<template>
    <v-container fluid class="user_show_wrapper">
        
        <div v-if="editedUser">
            <TopCard 
                :text="'User ' + editedUser.full_name"
            />

            <br>
            
            <v-tabs vertical class="user_show_card" v-model="currentTab">
                <template v-for="(tab, index) in tabs">
                    <v-tab :key="index" @click="setTab(index)">
                        {{tab.text}}
                    </v-tab>
                </template>

                <template v-for="(tab, index) in tabs">
                    <v-tab-item :key="index">

                        <div class="pl-5">
                            <component :user="editedUser" :is="tab.component" />
                        </div>
                    </v-tab-item>
                </template>
            </v-tabs>
        </div>

        <FormLoader v-else />

    </v-container>
</template>

<script>
import FormLoader from '../../components/Loaders/FormLoader.vue'
import TopCard from '../../components/Cards/TopCard.vue'
import UserDetails from '../../components/Users/UserDetails.vue'
import UserSecurity from '../../components/Users/UserSecurity.vue'
import UserSupport from '../../components/Users/UserSupport.vue'
import UserOrders from '../../components/Users/UserOrders.vue'
import UserActivity from '../../components/Users/UserActivity.vue'
import UserCourses from '../../components/Users/UserCourses.vue'

export default {
    components: {
        FormLoader,
        TopCard,
        UserDetails,
        UserSecurity,
        UserSupport,
        UserOrders,
        UserActivity,
        UserCourses,
    },

    data() {
        return {
            tabs: [
                { 
                    text: 'Details', 
                    component: 'UserDetails', 
                },
                { 
                    text: 'Security', 
                    component: 'UserSecurity', 
                },
                { 
                    text: 'Support', 
                    component: 'UserSupport', 
                },
                { 
                    text: 'Courses', 
                    component: 'UserCourses', 
                },
                { 
                    text: 'Orders', 
                    component: 'UserOrders', 
                }
            ],
            currentTab: 0,
            editedUser: null,
        }
    },

    created() {
        this.goToTab()
        this.getUser();
    },

    watch: {
        users() {
            this.getUser();
        }
    },

    computed: {
        users() {
            return this.$store.getters['UserState/users'];
        }
    },

    methods: {
        async getUser() {
            this.editedUser = await this.$store.dispatch('UserState/getUser', this.$route.params.userID);
        },

        submit() {
            this.errors = null;
            if(!this.$refs.form.validate()) {
                return;
            }

            this.loading = true;
            
            this.$store.dispatch('UserState/createUser', this.form)
                .then(res => {
                    this.$store.dispatch('MessageState/addMessage', {
                        message: `User ${this.form.first_name} ${this.form.last_name} created successfully`
                    });
                    this.$router.push('/users')
                })
                .catch(err => {
                    this.errors = err.errors;
                    this.$store.dispatch('MessageState/addMessage', {
                        message: 'Failed to create the user',
                        type: 'error',
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        setTab(index) {
            this.$router.push(
                {
                    path: this.$route.path,
                    query: { 
                        tab: index
                    }
                }
            )
        },
        
        goToTab() {
            this.currentTab = Number(this.$route.query.tab);
        },
    }
}
</script>

<style scoped>

    .user_show_card {
        min-height: 70vh;
    }

    ::v-deep .v-window {
        background-color: #eeeef1 !important;
    }

</style>