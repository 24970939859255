<template>
<div>
   UserActivity
</div>
</template>

<script>
export default {
data() {
return {

}
}
}
</script>

<style scoped>
</style>